import * as React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import PlanningCalendar, { bookingDates } from "../../components/PlanningCalendar/PlanningCalendar";
import { useEffect, useState } from "react";
import moment from "moment";

const GET_ORDER = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      estimatedDuration
      customer {
        id  
        name
      }
      photeshotEvent {
        id
        start
        end
        userId
      }
      photographyCompany {
        id
        name
        email
        phoneNo
        logoUrl
        primaryColor
        secondaryColor
        textColor
      } 
    }
  }
`;

const CREATE_CALENDAR_ITEM = gql`
  mutation createCalenderItem($input: CreateCalendarItemInput!) {
    createCalendarItem(input: $input) {
      id
    }
  }
`;

const PublicCalendarBooking: React.FC = () => {
  const [bookingDates, setBookingDates] = useState<null | bookingDates>(null);
  const [dateBooked, setDateBooked] = useState<boolean>(false);
  const [createCalendarItem, { error: createCalendarError, data: createCalendarData }] = useMutation(CREATE_CALENDAR_ITEM);

  const { orderId } = useParams();
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: orderId
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.getOrder.photeshotEvent) {
      setDateBooked(true);
      const eventData = {
        start: data.getOrder.photeshotEvent.start,
        end: data.getOrder.photeshotEvent.end,
        userId: data.getOrder.photeshotEvent.userId
      }
      setBookingDates(eventData);
    }
  }, [data]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }



  const handleDateSelected = (inpBookingDates: bookingDates) => {
    setBookingDates(inpBookingDates);
    if (inpBookingDates){
      setDateBooked(false);
    }
  }

  const handleBookedClicked = () => {
    if (bookingDates) {
      const input = {
        userId: bookingDates.userId,
        orderId: data.getOrder.id,
        start: bookingDates.start,
        end: bookingDates.end,
        eventType: 'PHOTOSHOT'
      }

      createCalendarItem({
        variables: {
          input: input,
        }
      }).then((res) => {
        setDateBooked(true);
      })
    }
  }

  const customerId = data.getOrder.customer.id;
  const fullAddress = data.getOrder.estateAddress + ', ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity;
  const estimatedDuration = data.getOrder.estimatedDuration;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '440px', paddingLeft: '40px', paddingRight: '40px', paddingTop: '40px', color: '#333' }}>
        {dateBooked ? (
          <>
             <h1>Tak for din booking</h1>

            <div style={{ marginTop: '40px', fontWeight: '600' }}>
              {moment(bookingDates?.start).format("dddd [d.] D. MMMM YYYY")} kl. {moment(bookingDates?.start).format("HH:mm")}
              <br />
            </div>
            <p>Vi har nu booket tiden til dig og glæder os til at møde dig og din bolig.</p>
            <p>Har du spørgsmål eller ønsker at ændre din booking kan du kontakt os på telefon: {data.getOrder.photographyCompany.phoneNo} eller via e-mail: {data.getOrder.photographyCompany.email}</p>
            <p></p>
            <span style={{ fontWeight: 'normal', fontStyle: 'italic', fontSize: '12px' }}>Vi ankommer +/- 30 minutter fra tidspunktet.</span>
            <p></p>
            <p>Venlig hilsen</p>
            <p>{data.getOrder.photographyCompany.name}</p>
            </>
        ) : (
          <>
            <h1 style={{ margin: '0px' }}>Book tid til fotografering</h1>
            <span style={{ fontSize: '10px', color: '#808080' }}>{fullAddress}</span>
            <p>Vi har brug for at vide hvornår vi kan komme ud og fotografere din bolig. Vælg en dato og tidspunkt i kalenderen herunder.</p>
            <PlanningCalendar fullAddress={fullAddress} customerId={customerId} duration={estimatedDuration} date={null} onDateSelected={handleDateSelected} />
            {(bookingDates && !dateBooked) &&
              (
                <div onClick={handleBookedClicked} className="timeBox textBold" style={{ cursor: 'pointer', marginTop: '60px', border:'0px', backgroundColor: '#1ace1a' }}>
                  <div style={{ width: '30px' }}>

                  </div>
                  <div className="flexColumn">
                    <div>Klik her for at booke tiden</div>
                  </div>
                  <div>
                    <div style={{ width: '30px' }}></div>
                  </div>
                </div>
              )}
           
          </>
        )
        }
      </div>
    </div>
  );
};

export default PublicCalendarBooking;
