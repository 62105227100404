import React, { FC, useEffect, useState } from "react";
//import { useTranslation } from 'react-i18next';
import moment from "moment";
import { gql, useLazyQuery } from "@apollo/client";
import { datastore } from "../../datastore";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/da'; // Import Danish locale
import './PlanningCalendar.css';
import PlanningTimeSuggestionBox from "./PlanningTimeSuggestionBox";
import { set } from "react-datepicker/dist/date_utils";

const GET_TIME_SUGGESTIONS = gql`
  query getTimeSuggestions($input: GetTimeSuggestionsInput!) {
    getTimeSuggestions(input: $input) {
      id
      start
      end
      userId
      savedCo2
    }
  }
`;

const GET_AVALIBLE_DAYS = gql`
  query getAvalibleDays($input: getAvalibleDaysInput!) {
    getAvalibleDays(input: $input) {
      id
      date
    }
  }
`;

export interface PlanningCalendarProps {
  fullAddress: string
  customerId: string
  duration: number
  date: bookingDates | null
  onDateSelected: any
}

export type bookingDates = {
  start: string | null,
  end: string | null,
  userId: string | null
}

enum PlanningState {
  DATE_NOT_SELECTED,
  SELECT_DATE,
  DATE_SELECTED,
  TIME_SELECTED,
  DATE_SELECTED_SHOW_ALL
}

const PlanningCalendar: FC<PlanningCalendarProps> = (props) => {

  let inputPlanningState = PlanningState.DATE_NOT_SELECTED;
  let inputStartDate = null;
  let inputEndDate = null;
  if (props.date !== null) {
    inputStartDate = props.date.start;
    inputEndDate = props.date.end;
    inputPlanningState = PlanningState.TIME_SELECTED;
  }

  const [selectedDate, setSelectedDate] = useState(null);
  const [planningState, setPlanningState] = useState<PlanningState>(inputPlanningState);
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(inputStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(inputEndDate);
  const [validDates, setValidDates] = useState<any[] | null>(null);

  const token = localStorage.getItem('id_token') + '';
  datastore.data.token = token;

  let input = {
    fullAddress: props.fullAddress,
    customerId: props.customerId,
    duration: props.duration,
  } as any;

  

  const [getAvalibleDays, { loading: loadingDays, data: dataDays }] = useLazyQuery(GET_AVALIBLE_DAYS, {
    variables: {
      input: {
        fullAddress: props.fullAddress,
        customerId: props.customerId,
        duration: props.duration,
      }
    },
    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [getTimeSuggestions, { loading, data }] = useLazyQuery(GET_TIME_SUGGESTIONS, {

    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (typeof getTimeSuggestions === 'function') {
      getTimeSuggestions({
        variables: {
          input: {
            fullAddress: props.fullAddress,
            customerId: props.customerId,
            duration: props.duration,
          }
        }
      });
    } else {
      console.error('getTimeSuggestions is not a function or is undefined');
    }
  }, [getTimeSuggestions, props.fullAddress, props.customerId, props.duration]);


  /*
    const { loading, error, data } = useQuery(GET_TIME_SUGGESTIONS, {
      variables: {
        input: input
      },
      context: {
        headers: {
          authorization: "Bearer " + token,
        },
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    });
    */

  if (loading) return (
    <div className="planningCalendar">
      <div className="loaderContainer">
        <div className="loader"></div>
      </div>
    </div>
  );

  if (loadingDays) return (
    <div className="planningCalendar">
      <div className="loaderContainer">
        <div className="loader"></div>
      </div>
    </div>
  );

  if (dataDays && validDates === null) {
    //map dates
    let dates = dataDays.getAvalibleDays.map((day: any) => {
      return day.date.split('T')[0];
    });

    //console.log('-->dates:', dates); 
    setValidDates(dates);
  }

  if (!data) return (
    <div className="planningCalendar">
      <div className="loaderContainer">
        <div className="loader"></div>
      </div>
    </div>
  );



  const isDateValid = (date: any) => {
    if (validDates === null) {
      return false;
    }
    const formattedDate = date.format("YYYY-MM-DD");
    return validDates.includes(formattedDate);
  };

  moment.locale('da');


  const getFormattedDateHeading = () => {
    let formateretDato = moment(selectedDate).format("dddd [d.] D. MMMM");
    return formateretDato.charAt(0).toUpperCase() + formateretDato.slice(1);
  }

  const getNumberOfDaysHeading = () => {
    const numberOfDays = moment(selectedDate).diff(moment(), 'days') + 1;
    if (numberOfDays === 1) {
      return "I morgen";
    } else {
      return "Om " + numberOfDays + " dage"
    }
  }

  const handleShowOtherDates = () => {
    setPlanningState(PlanningState.SELECT_DATE);
    getAvalibleDays();
  }

  const handleShowAllTimeSlot = () => {
    setPlanningState(PlanningState.DATE_SELECTED_SHOW_ALL);
  }

  const handleTimeClicked = (timeSuggestion: bookingDates) => {
    console.log('timeSuggestion:', timeSuggestion);
    setPlanningState(PlanningState.TIME_SELECTED);
    setSelectedStartDate(timeSuggestion.start);
    setSelectedEndDate(timeSuggestion.end);
    props.onDateSelected(timeSuggestion);
  }

  const handleDateChanged = (newDate: any) => {

    setSelectedDate(newDate);
    //add 1 day to date
    newDate = moment(newDate).add(1, 'days');


    getTimeSuggestions({
      variables: {
        input: {
          fullAddress: props.fullAddress,
          customerId: props.customerId,
          duration: props.duration,
          date: moment(newDate).toISOString()
        }
      }
    }).then(() => {
      setPlanningState(PlanningState.DATE_SELECTED);
    })

    //setSelectedDate(newDate);
  }

  const showDateSuggestions = (timeSuggestions: any) => {
    let resultJSX = [];
    let addedSuggestions = [];
    let added = 0;
    for (let i = 0; i < timeSuggestions.length; i++) {
      if (added < 3) {
        console.log('timeSuggestions[i].savedCo2:' + timeSuggestions[i].savedCo2)
        if (timeSuggestions[i].savedCo2 > 0) {
          added++;
          addedSuggestions.push(timeSuggestions[i]);
          //resultJSX.push(<PlanningTimeSuggestionBox key={i} timeSuggestion={timeSuggestions[i]} />);
        }
      } else {
        break;
      }
    }


    if (added < 3) {
      for (let i = 0; i < timeSuggestions.length; i++) {
        if (added < 3) {

          if (timeSuggestions[i].savedCo2 === 0) {
            added++;
            addedSuggestions.push(timeSuggestions[i]);
            //resultJSX.push(<PlanningTimeSuggestionBox key={i} timeSuggestion={timeSuggestions[i]} />);
          }
        } else {
          break;
        }
      }
    }


    //sort addedSuggestions by start time
    addedSuggestions.sort((a: any, b: any) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();

    });

    for (let i = 0; i < addedSuggestions.length; i++) {
      resultJSX.push(<PlanningTimeSuggestionBox key={i} timeSuggestion={addedSuggestions[i]} handleTimeClicked={handleTimeClicked} />);
    }

    return resultJSX;
  }

  if (planningState === PlanningState.SELECT_DATE && validDates === null) {
    getAvalibleDays();
  }

  return (
    <div className="planningCalendar">
      {planningState === PlanningState.DATE_NOT_SELECTED && (
        <div style={{ width: '100%' }}>
          <div className="textHeading">
            Vælg en ledig dato & tidspunkt
          </div>
          <div className="textNomal spaceTop">
            Foreslåede tider:
          </div>
          <div>
            {data.getTimeSuggestions.map((timeSuggestion: any) => (
              <PlanningTimeSuggestionBox timeSuggestion={timeSuggestion} handleTimeClicked={handleTimeClicked} />
            ))}
          </div>

          <div className="textItalic spaceTop">
            Der er flere ledige tider:
          </div>
          <div className="timeBox" style={{ justifyContent: 'center', gap: '10px' }} onClick={() => handleShowOtherDates()}>
            <FaRegCalendarAlt />
            <div className="textBold">Vis flere datoer</div>
          </div>
        </div>
      )}

      {planningState === PlanningState.SELECT_DATE && (
        <div>
          <div className="textHeading">
            Vælg en ledig dato
          </div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              onChange={(newDate: any) => handleDateChanged(newDate)}
              shouldDisableDate={(date: any) => !isDateValid(date)}
              orientation="landscape"
              openTo="day"
              slotProps={{
                actionBar: { actions: [] },
                calendarHeader: {
                  sx: {
                    position: "relative",
                    '& .MuiPickersArrowSwitcher-root': {
                      width: 0
                    },
                    "& .MuiPickersCalendarHeader-labelContainer": {
                      margin: "auto",
                      textTransform: "capitalize"
                    },
                    "& .MuiIconButton-edgeEnd": {
                      position: "absolute",
                      left: 0, top: 0, bottom: 0
                    },
                    "& .MuiIconButton-edgeStart": {
                      position: "absolute",
                      right: 0, top: 0, bottom: 0
                    }
                  }
                },
                leftArrowIcon: {
                  sx: { color: "#000", fontSize: "2rem" }
                },
                rightArrowIcon: {
                  sx: { color: "#000", fontSize: "2rem" }
                },
              }}
              value={null}
              slots={{
                toolbar: () => null, // Hide the toolbar
              }}
              sx={{

                '.Mui-selected': {
                  backgroundColor: 'grey !important', // Change the background color of the selected day
                  color: 'white', // Change the text color (optional)
                },
                '.MuiPickersDay-root:hover': {
                  backgroundColor: 'lightgrey !important', // Optional: Change the hover effect color
                },
                '.MuiDayCalendar-header': {
                  borderBottom: '1px solid grey', // Add a grey border under the weekdays
                  marginBottom: '4px', // Optional: Add spacing for better visibility
                },
              }}
              views={['day']}
              displayWeekNumber={false}
            />
          </LocalizationProvider>
        </div>
      )}

      {planningState === PlanningState.DATE_SELECTED && (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
            <FaRegArrowAltCircleLeft style={{ cursor: 'pointer' }} fontSize={'28px'} onClick={() => {setPlanningState(PlanningState.SELECT_DATE);props.onDateSelected(null)  }} />
            <div>
              <div className="textHeading">
                {getFormattedDateHeading()}
              </div>
              <div className="textItalic">
                {getNumberOfDaysHeading()}
              </div>
            </div>
          </div>
          <div className="textNomal spaceTop">
            Foreslåede tider:
          </div>
          <div>
            {
              showDateSuggestions(data.getTimeSuggestions)
            }

          </div>
          <div className="textItalic spaceTop">
            Der er flere tider denne dag:
          </div>
          <div className="timeBox" style={{ justifyContent: 'center', gap: '10px' }} onClick={() => handleShowAllTimeSlot()}>
            <FaRegCalendarAlt />
            <div className="textBold">Vis flere tider</div>
          </div>
        </div>
      )}

      {planningState === PlanningState.DATE_SELECTED_SHOW_ALL && (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
            <FaRegArrowAltCircleLeft style={{ cursor: 'pointer' }} fontSize={'28px'} onClick={() => {setPlanningState(PlanningState.SELECT_DATE); props.onDateSelected(null) }} />
            <div>
              <div className="textHeading">
                {getFormattedDateHeading()}
              </div>
              <div className="textItalic">
                {getNumberOfDaysHeading()}
              </div>
            </div>
          </div>
          <div className="textNomal spaceTop">
            Alle ledige tider:
          </div>
          <div className="allTimeSlots">
            {data.getTimeSuggestions.map((timeSuggestion: any) => (
              <PlanningTimeSuggestionBox timeSuggestion={timeSuggestion} handleTimeClicked={handleTimeClicked} />
            ))}
          </div>
        </div>
      )}

      {planningState === PlanningState.TIME_SELECTED && (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'start', gap: '10px', alignItems: 'center' }}>
            <FaRegArrowAltCircleLeft style={{ cursor: 'pointer' }} fontSize={'28px'} onClick={() => {setPlanningState(PlanningState.SELECT_DATE);props.onDateSelected(null);  }} />
            <div>
              <div className="textHeading" style={{ cursor: 'pointer' }} onClick={() => {setPlanningState(PlanningState.SELECT_DATE);props.onDateSelected(null);}}>
                Klik her for at vælge en anden tid.
              </div>
            </div>
          </div>
          <div style={{ marginTop: '90px', textAlign: 'center', fontWeight: '600' }}>
            {moment(selectedStartDate).format("dddd [d.] D. MMMM YYYY")} kl. {moment(selectedStartDate).format("HH:mm")}
            <br/>
            <span style={{ fontWeight: 'normal', fontStyle: 'italic', fontSize: '12px' }}>Vi ankommer +/- 30 minutter fra valgt tidspunkt.</span>
          </div>
        
        </div>
      )}
    </div>
  )
}

export default PlanningCalendar;